<template>
	<div class="visa-detail-total">
		<div class="content" v-show="show">
			<div class="top-bar">
				<div class="left">
					<div class="image">
						<img style="width: 100%; height: 100%; object-fit: fill" :src="object.icon" />
					</div>
				</div>
				<div class="right">
					<div class="title">
						{{ object.title }}
					</div>
					<div class="gray-bar">
						<div class="price-bar">
							<div class="price-group">
								<div class="price-rmb">RMB</div>
								<div class="price">{{ object.price }}</div>
								<div class="price-tag">起</div>
							</div>
							<el-popover placement="top-start" width="400" trigger="hover">
								<div>
									<div>起价说明</div>
									<div v-if="object.confirm == 1">
										<br />
										<div>一次确认</div>
										<div>
											本产品展示价格即最终购买价格，您可直接下单购买，成功付款后我们将尽快与您联系，如有任何疑问可直接联系客服
										</div>
									</div>
								</div>
								<div slot="reference" class="price-start-group">
									<div class="price-start">起价说明</div>
									<img src="../assets/kh/ico_qjsm@2x.png" class="price-start-icon" />
								</div>
							</el-popover>
							<div class="pro-number">编号{{ object.id }}</div>
						</div>
						<div class="service-bar">
							<div class="service-tag">{{ object.service_tag }}</div>
							<div class="service-type">{{ object.service_type }}</div>
							<div class="service-content">{{ object.service_content }}</div>
						</div>
					</div>

					<div class="visa-info">
						<div class="visa-info-content">签证类型：{{ object.type }}</div>
						<div class="visa-info-content">签证地：{{ object.startCity }}</div>
						<div class="visa-info-content">
							签证有效期：{{ object.limitTime }}
						</div>
						<div class="visa-info-content">
							可停留天数：{{ object.stayDays }}
						</div>
						<div class="visa-info-content">入境次数：{{ object.myTimes }}</div>
					</div>

					<el-popover placement="right" width="200" trigger="click">
						<div style="
                display: flex;
                flex-direction: column;
                width: 200px;
                height: 225px;
                align-items: center;
              ">
							<div style="
                  font-size: 14px;
                  line-height: 24px;
                  color: #333333;
                  text-align: center;
                  margin-top: 10px;
                  opacity: 1;
                ">
								<div>{{ wx_code == "" ? "加载中" : "请使用微信扫一扫" }}</div>
								<div>{{ wx_code == "" ? "" : "识别二维码预定" }}</div>
							</div>
							<img v-show="wx_code != ''" style="margin-top: 10px; width: 150px; height: 150px"
								:src="wx_code" />
						</div>
						<div slot="reference" class="btn-buy" @click="clickBuy">
							立即预定
						</div>
					</el-popover>

					<img src="https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/zxdh_dz.png" class="buy-icon" />
				</div>
			</div>

			<div class="line"></div>
			<div class="tab-banner">
				<el-menu :default-active="activeIndex == null ? '1' : activeIndex" mode="horizontal"
					@select="handleSelect">
					<el-menu-item index="1">签证信息</el-menu-item>
					<el-menu-item index="2">签证材料</el-menu-item>
					<el-menu-item index="3">预订信息</el-menu-item>
					<el-menu-item index="4">特别提醒</el-menu-item>
					<el-menu-item index="5">递送材料</el-menu-item>
				</el-menu>
			</div>
			<div id="MENU1" class="info-banner">
				<img src="../assets/kh/ico_jbxx@2x.png" class="info-icon" />
				<div class="info-title">签证信息</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.basic_info"></div>
			</div>
			<div id="MENU2" class="info-banner">
				<img src="../assets/kh/ico_qzcl@2x.png" class="info-icon" />
				<div class="info-title">签证材料</div>
				<div class="info-line"></div>
				<div class="info-visa-content-array">
					<div class="visa-content-menu-array">
						<div :class="
                index == visa_index ? 'visa-menu-item' : 'visa-menu-item-no'
              " v-for="(item, index) in object.basic_visa" :key="item.type" @click="clickVisaTab(index)">
							{{ item.type }}
						</div>
					</div>
					<div class="visa-content-list">
						<div class="visa-list-item" v-for="item in object.basic_visa[visa_index].list"
							:key="item.Title">
							<div class="visa-list-item-title">
								{{ item.Title + (item.Required == 1 ? "*" : "") }}
							</div>
							<div class="visa-list-item-content" v-html="item.Contents"></div>
						</div>
					</div>
				</div>
			</div>
			<div id="MENU3" class="info-banner">
				<img src="../assets/kh/ico_ydxx@2x.png" class="info-icon" />
				<div class="info-title">预订信息</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.basic_msg"></div>
			</div>
			<div id="MENU4" class="info-banner">
				<img src="../assets/kh/ico_tbtx@2x.png" class="info-icon" />
				<div class="info-title">特别提醒</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.basic_tip"></div>
			</div>
			<div id="MENU5" class="info-banner">
				<img src="../assets/kh/ico_dscl@2x.png" class="info-icon" />
				<div class="info-title">递送材料</div>
				<div class="info-line"></div>
				<div class="info-content" v-html="object.basic_stuff"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getVisaDetail,
		getDetailCode
	} from "@/api/index";
	export default {
		data() {
			return {
				visa_index: 0,
				activeIndex: "1",
				wx_code: "",
				show: false,
				object: {
					icon: "",
					title: "",
					type: "", //签证类型
					confirm: 1, //一二次确认
					price: 0, //价格
					id: 0, //编号
					service_tag: "", //服务
					service_type: "", //二次确认
					service_content: "", //对应描述
					startCity: "", //送签地
					limitTime: "", //有效期
					myTimes: "", //次数
					stayDays: "", //可停留天数
					basic_info: "",
					basic_msg: "",
					basic_tip: "",
					basic_stuff: "",
					basic_visa: [],
				},
				productId: 0,
				now_image_index: 0,
				now_image_page: 1,
			};
		},
		created() {
			this.productId = Number(this.$route.query.id);
			this.getVisaDetail();
		},
		methods: {
			clickVisaTab(e) {
				this.visa_index = e;
			},
			handleSelect(e) {
				console.log(e);
				if (e == "1") {
					document.getElementById("MENU1").scrollIntoView();
				} else if (e == "2") {
					document.getElementById("MENU2").scrollIntoView();
				} else if (e == "3") {
					document.getElementById("MENU3").scrollIntoView();
				} else if (e == "4") {
					document.getElementById("MENU4").scrollIntoView();
				} else if (e == "5") {
					document.getElementById("MENU5").scrollIntoView();
				}
			},
			clickBuy() {
				if (this.wx_code != "" || this.object.price == 0) return;

				let app;
				if (this.$app == "kh") app = 3;
				else if (this.$app == "dy") app = 2;

				getDetailCode(
					this.productId + ",0",
					"pagesPass/pages/passdetail/passdetail",
					app
				).then((res) => {
					this.wx_code = res;
				});
			},
			clickArrow(e) {
				if (e == 1) {
					//左箭头
					if (this.now_image_page > 0) this.now_image_page--;
				} else {
					//右箭头
					if (this.now_image_page < Math.ceil(this.object.imgArray.length / 5)) {
						this.now_image_page++;
					}
				}
			},
			getVisaDetail() {
				getVisaDetail(this.productId).then((res) => {
					this.object = res;
					this.show = true;
					console.log(this.object);

					if (res.VisaProduct.VisaType == 0) {
						this.object.type = "其他";
					} else if (res.VisaProduct.VisaType == 1) {
						this.object.type = "旅游";
					} else if (res.VisaProduct.VisaType == 2) {
						this.object.type = "探亲";
					} else if (res.VisaProduct.VisaType == 3) {
						this.object.type = "商务";
					} else if (res.VisaProduct.VisaType == 4) {
						this.object.type = "团签";
					}
					this.object.icon = res.visa_pic[0].img;
					this.object.title = res.VisaProduct.Title;
					this.object.price = res.VisaProduct.Price;
					this.object.id = res.VisaProduct.ID;
					this.object.service_tag = "签证";
					this.object.service_type = res.VisaProduct.mdd;
					this.object.service_content = res.VisaProduct.JXPrice;
					this.object.confirm = 1;
					this.object.startCity = res.VisaProduct.StartCity; //送签地
					this.object.limitTime = res.VisaProduct.LimitTime; //签证有效期
					this.object.myTimes = res.VisaProduct.Times; //签证次数
					this.object.stayDays = res.VisaProduct.StayDays; //可停留天数    ● 供应商：甜程旅行网
					this.object.basic_info = `
        ● 签证类型：${this.object.type}<br/>
        ● 办理时长：${res.VisaProduct.WorkTime}<br/>
        ● 入境次数：${res.VisaProduct.Times}<br/>
        ● 可停留天数：${res.VisaProduct.StayDays}<br/>
        ● 签证有效期：${res.VisaProduct.LimitTime}<br/>
        ● 受理范围：<br/>${res.VisaProduct.Area}<br/>
     `;
					this.object.basic_msg = res.VisaProduct.Mark_01;
					this.object.basic_tip = res.VisaProduct.Mark_02;
					this.object.basic_stuff = res.VisaProduct.Mark_03;
					this.object.basic_visa = res.visa[0].list1;
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.visa-detail-total /deep/ .el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 4px solid var(--hover_color);
	}

	.visa-detail-total /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
		border-bottom: 4px solid var(--hover_color);
	}

	.visa-detail-total /deep/ .el-menu.el-menu--horizontal {
		border-bottom: 1px solid var(--hover_color);
	}

	.visa-detail-total /deep/ .el-menu-item {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 12px;
		padding-right: 12px;
	}

	.visa-detail-total /deep/ .el-submenu__title {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 12px;
		- padding-right: 12px;
	}

	.visa-detail-total /deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		margin-left: 2px;
	}

	.visa-detail-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #fff;

		div.visa-info {
			display: flex;
			flex-wrap: wrap;

			div.visa-info-content {
				margin-top: 24px;
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				opacity: 1;
				margin-right: 60px;
			}
		}

		.tab-banner {
			position: sticky;
			top: 0px;
		}

		div.info-banner {
			display: flex;
			padding-top: 42px;
			padding-bottom: 42px;
			border-bottom: 1px solid #e6e6e6;

			img.info-icon {
				width: 36px;
				height: 36px;
				margin-top: 15px;
				object-fit: fill;
			}

			.info-title {
				margin-left: 6px;
				font-size: 18px;
				line-height: 22px;
				margin-top: 23px;
				width: 150px;
				color: #333333;
				opacity: 1;
			}

			div.info-line {
				margin-right: 51px;
				width: 1px;
				height: 67px;
				background-color: #e6e6e6;
				opacity: 1;
			}

			div.info-content {
				font-size: 14px;
				line-height: 30px;
				margin-top: 18px;
				color: #666666;
				width: 100%;
				opacity: 1;
			}

			.info-visa-content-array {
				margin-top: 18px;
				width: 100%;
				opacity: 1;

				div.visa-content-menu-array {
					display: flex;

					div.visa-menu-item {
						width: 121px;
						height: 42px;
						background: #00AF67;
						opacity: 1;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						line-height: 42px;
						color: #FFFFFF;
						text-align: center;
						margin-right: 20px;
						cursor: pointer;
					}

					div.visa-menu-item-no {
						width: 121px;
						height: 42px;
						opacity: 1;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						line-height: 42px;
						color: #333;
						text-align: center;
						margin-right: 20px;
						cursor: pointer;
					}
				}

				div.visa-content-list {
					margin-top: 10px;
					display: flex;
					flex-direction: column;
					width: 1010px;
					border: 1px solid #E4E4E4;
					padding-left: 30px;
					box-sizing: border-box;
					padding-bottom: 30px;

					div.visa-list-item {
						margin-top: 30px;
						width: 950px;
						border-bottom: 1px solid #E4E4E4;
						opacity: 1;
						box-sizing: border-box;
						padding-bottom: 30px;

						div.visa-list-item-title {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 24px;
							color: #333333;
							opacity: 1;
						}

						div.visa-list-item-content {
							margin-top: 20px;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							line-height: 24px;
							color: #747474;
							opacity: 1;
						}
					}
				}
			}

			.trip-null {
				margin-left: 10px;
				margin-top: 22px;
				font-size: 14px;
				line-height: 21px;
				width: 680px;
				color: #666666;
				opacity: 1;
			}

			div.trip-banner {
				display: flex;
				flex-direction: column;
				width: 100%;

				div.trip-item {
					display: flex;
					flex-direction: column;
					margin-top: 12px;

					.trip-line {
						width: 861px;
						height: 1px;
						background-color: #e6e6e6;
						opacity: 1;
					}

					div.trip-title-banner {
						display: flex;
						margin-bottom: 20px;

						img.trip-title-icon {
							width: 40px;
							height: 40px;
							object-fit: fill;
						}

						div.trip-title {
							font-size: 18px;
							line-height: 22px;
							font-weight: bold;
							width: 680px;
							color: #333333;
							opacity: 1;
							margin-left: 10px;
							margin-top: 10px;
						}

						div.trip-content {
							margin-left: 10px;
							font-size: 14px;
							line-height: 21px;
							width: 680px;
							color: #666666;
							opacity: 1;
						}

						div.trip-content-ship {
							margin-left: 10px;
							font-size: 14px;
							line-height: 21px;
							width: 680px;
							color: #666666;
							margin-top: 10px;
							opacity: 1;
						}

						div.trip-content2 {
							margin-left: 10px;
							margin-top: 10px;
							font-size: 14px;
							line-height: 21px;
							width: 680px;
							color: #666666;
							opacity: 1;
						}
					}
				}
			}

			div.info-content-banner {
				display: flex;
				flex-direction: column;
				margin-top: 22px;
				width: 100%;

				div.info-content-title {
					font-size: 18px;
					line-height: 22px;
					color: #4d4d4d;
					opacity: 1;
				}

				div.info-content-value {
					margin-top: 24px;
					font-size: 14px;
					line-height: 21px;
					color: #666666;
					opacity: 1;
				}

				div.info-content-line {
					margin-top: 36px;
					margin-bottom: 36px;
					width: 920px;
					height: 0px;
					border-bottom: 1px dashed #cccccc;
				}
			}
		}

		img:hover {
			cursor: pointer;
		}

		.content {
			width: 1200px;
			background-color: #fff;
			margin: 0 auto;

			div.top-bar {
				margin-top: 28px;
				display: flex;
				padding-bottom: 21px;

				div.group-theme {
					display: flex;

					div.item-theme {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-right: 10px;
						margin-top: 14px;

						img.item-theme-img {
							width: 44px;
							height: 36px;
							object-fit: fill;
						}

						div.item-theme-value {
							width: 75px;
							height: 18px;
							background: #f2f2f2;
							text-align: center;
							opacity: 1;
							border-radius: 2px;
							font-size: 12px;
							line-height: 18px;
							color: #333333;
						}

						div.item-theme-title {
							margin-top: 5px;
							font-size: 12px;
							line-height: 14px;
							color: #333333;
							opacity: 1;
						}
					}
				}

				div.ticket-theme {
					display: flex;
					flex-direction: column;
					margin-top: 25px;

					div.item-theme {
						display: flex;
						align-items: center;

						img.item-theme-img {
							width: 16px;
							height: 16px;
							object-fit: fill;
						}

						div.item-theme-value {
							margin-left: 20px;
							font-size: 14px;
							font-weight: 400;
							line-height: 36px;
							color: #333333;
							opacity: 1;
						}
					}
				}

				.prize {
					width: 670px;
					height: 28px;
					object-fit: fill;
				}

				.buy-icon {
					width: 670px;
					height: 64px;
					margin-top: 30px;
					margin-left: auto;
					object-fit: fill;
				}

				.btn-buy {
					width: 180px;
					height: 52px;
					background: #fe6f59;
					border-radius: 3px;
					text-align: center;
					font-size: 20px;
					font-weight: 400;
					line-height: 52px;
					color: #ffffff;
					margin-top: 50px;
					cursor: pointer;
				}

				.btn-buy-gray {
					width: 180px;
					height: 52px;
					background: #ccc;
					border-radius: 3px;
					text-align: center;
					font-size: 20px;
					font-weight: 400;
					line-height: 52px;
					color: #ffffff;
					margin-top: 50px;
					cursor: pointer;
				}

				div.left {
					display: flex;
					flex-direction: column;

					.image {
						width: 504px;
						height: 336px;
					}

					div.child-image-bar {
						display: flex;
						width: 504px;
						margin-top: 6px;

						.arrow-left {
							width: 21px;
							height: 64px;
							object-fit: fill;
						}

						.arrow-right {
							width: 21px;
							height: 64px;
							margin-left: 5px;
							object-fit: fill;
						}

						.child-image-array {
							display: flex;
							width: 504px;

							div.child-image {
								width: 87px;
								height: 64px;
								margin-left: 5px;
							}
						}
					}
				}

				div.right {
					display: flex;
					width: 670px;
					flex-direction: column;
					margin-left: 26px;

					.title {
						width: 670px;
						font-size: 21px;
						line-height: 25px;
						color: #4a4a4a;
						opacity: 1;
					}

					div.gray-bar {
						margin-top: 20px;
						display: flex;
						flex-direction: column;
						width: 100%;
						background: #fffbf3;

						div.price-bar {
							display: flex;
							padding-left: 16px;
							padding-bottom: 17px;
							border-bottom: 1px solid #f2e9d6;

							div.price-group {
								display: flex;
								margin-top: 21px;

								div.price-rmb {
									width: 43px;
									height: 24px;
									font-size: 18px;
									line-height: 22px;
									color: #666666;
									margin-top: 18px;
									opacity: 1;
								}

								div.price {
									height: 47px;
									font-size: 36px;
									line-height: 43px;
									color: #e32828;
									opacity: 1;
								}

								div.price-tag {
									width: 18px;
									height: 18px;
									margin-left: 5px;
									margin-top: 18px;
									font-size: 18px;
									line-height: 22px;
									color: #666666;
									opacity: 1;
								}
							}

							div.price-start-group {
								display: flex;
								margin-top: 41px;
								cursor: pointer;

								div.price-start {
									width: 56px;
									height: 14px;
									font-size: 14px;
									line-height: 17px;
									color: #fe6f59;
									opacity: 1;
									margin-left: 10px;
								}

								img.price-start-icon {
									margin-top: 3px;
									width: 13px;
									height: 13px;
									object-fit: fill;
									margin-left: 5px;
								}
							}

							div.pro-number {
								margin-top: 44px;
								margin-left: 10px;
								width: 57px;
								height: 12px;
								font-size: 12px;
								line-height: 14px;
								color: #5a5a5a;
								opacity: 1;
							}
						}

						div.service-bar {
							display: flex;
							margin-left: 16px;
							margin-top: 12px;
							margin-bottom: 10px;
							height: 18px;

							div.service-tag {
								font-size: 11px;
								line-height: 18px;
								color: #333333;
								padding-left: 8px;
								padding-right: 8px;
								background: #ffffff;
								border-radius: 2px 0px 0px 2px;
								opacity: 1;
							}

							div.service-type {
								height: 18px;
								background: #fe6f59;
								line-height: 18px;
								padding-left: 8px;
								padding-right: 8px;
								opacity: 1;
								border-radius: 0px 2px 2px 0px;
								font-size: 11px;
								color: #ffffff;
								opacity: 1;
							}

							div.service-content {
								font-size: 12px;
								margin-left: 10px;
								line-height: 18px;
								color: #989898;
								opacity: 1;
							}
						}
					}
				}
			}

			div.line {
				width: 100%;
				height: 20px;
				background: #f5f7f8;
				opacity: 1;
			}
		}
	}
</style>